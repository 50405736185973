function Time(timestamp) {
    this.timestamp = timestamp;
}

Time.minute = 60;
Time.hour = Time.minute * 60;
Time.day = Time.hour * 24;
Time.month = Time.day * 30;
Time.year = Time.day * 365;

Time.days = {
    0: "Sonntag",
    1: "Montag",
    2: "Dienstag",
    3: "Mittwoch",
    4: "Donnerstag",
    5: "Freitag",
    6: "Samstag"
};
Time.monthes = {
    1: "Januar",
    2: "Februar",
    3: "März",
    4: "April",
    5: "Mai",
    6: "Juni",
    7: "Juli",
    8: "August",
    9: "September",
    10: "Oktober",
    11: "November",
    12: "Dezember"
};

Time.prototype.timestamp = 0;

Time.prototype.isRelative = function (now) {
    now = now || new Date();

    var date = new Date(this.timestamp * 1000);
    return ((now - date) / 1000) < Time.day;
};

Time.prototype.formatAbsolute = function (time, now) {
    time = time || new Date(this.timestamp * 1000);
    now = now || new Date();
    return "am " + time.getDate() + ". " + Time.monthes[time.getMonth() + 1] + ((time.getFullYear() !== now.getFullYear()) ? " " + time.getFullYear() : "") + " " + time.getHours() + ":" + ((("" + time.getMinutes()).length === 1) ? "0" + time.getMinutes() : time.getMinutes()) + " Uhr";
};

Time.prototype.formatRelative = function (time, now) {
    time = time || new Date(this.timestamp * 1000);
    now = now || new Date();
    var diff = Math.round((now - time) / 1000), prefix = ((diff < 0) ? "in " : "vor ");
    if (diff > Time.year) {
        var years = Math.floor(diff / Time.year);
        return prefix + years + ((years === 1) ? " Jahr" : " Jahren");
    }
    if (diff > Time.month) {
        var months = Math.floor(diff / Time.month);
        return prefix + months + ((months === 1) ? " Monat" : " Monaten");
    }
    if (diff > Time.day) {
        var days = Math.floor(diff / Time.day);
        if (days < 3) {
            if (days === 2) {
                return (diff < 0) ? "&Uuml;bermorgen" : "Vorgestern";
            }
            if (days === 1) {
                return (diff < 0) ? "Morgen" : "Gestern";
            }
        }
        return prefix + days + " Tagen";
    }
    if (diff > Time.hour) {
        var hours = Math.floor(diff / Time.hour);
        return prefix + hours + ((hours === 1) ? " Stunde" : " Stunden");
    }
    if (diff > Time.minute) {
        var minutes = Math.floor(diff / Time.minute);
        return prefix + minutes + ((minutes === 1) ? " Minute" : " Minuten");
    }
    if (diff > 30) {
        return prefix + diff + " Sekunden";
    }
    if (diff < 11) {
        return "jetzt";
    }
    return prefix + "wenigen Sekunden";
};

Time.prototype.format = function (now) {
    now = now || new Date();

    var date = new Date(this.timestamp * 1000);
    if (this.isRelative(now)) {
        return this.formatRelative(date, now);
    }
    return this.formatAbsolute(date, now);
};