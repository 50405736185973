
String.prototype.contains = function(it) { return this.indexOf(it) !== -1; };

function API() {}

API.base = "https://bair.io/";
API.path = "api/";

API.init = function(data) {
    if (data.hasOwnProperty("base")) {
        API.base = data.base;
    }
};

API.serialize = function(obj) {
    var str = [];
    for(var p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    }
    return str.join("&");
};

API.readCookie = function(name) {
    var cookie = " " + document.cookie,
        index = cookie.indexOf(" " + name + "=");
    if (index === -1) {
        index = cookie.indexOf(";" + name + "=");
    }
    if (index === -1 || name === ""){
        return "";
    } 
    var index1 = cookie.indexOf(";", index + 1);
    if (index1 === -1) {
        index1 = cookie.length;
    }
    return cookie.substring(index + name.length + 2, index1);
};
API.deleteCookie = function(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
API.setCookie = function(name, value, days) {
    var today = new Date();
    var expire = new Date();
    if (days === null || days === 0) {days = 1;}
    expire.setTime(today.getTime() + 3600000 * 24 * days);
    document.cookie = name + "=" + (value) + ";expires=" + expire.toGMTString();
};

API.strip = function(html) {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
};

API.get = function(path, callback) {
    API.post(path, {}, callback);
};

API.post = function(path, params, callback) {
    if (typeof(path) === "undefined") {
        throw "undefined path";
    }
    if (typeof(params) === "undefined") {
        params = {};
    }
    $.ajax({
        type: "POST",
        url: API.base + API.path + path,
        data: params}).always(function (data) {
            if (typeof(data) === "object") {
                if (data.hasOwnProperty("success") && data.hasOwnProperty("message")) {
                    callback(String(data.success) === "true", data.message, data.hasOwnProperty("data") ? data.data : {});
                } else {
                    console.error("response format was not valid");
                    callback(false, "bad response format (invalid data-format)", {});
                }
            } else {
                console.error("response was no json");
                callback(false, "bad response format (invalid JSON)", {});
            }
    });
};

API.pushURL = function(url, title) {
    history.pushState({dev: "tec"}, title, url);
};

API.isActiveTab = true;
window.onfocus = function() {
    API.isActiveTab = true;
};

window.onblur = function() {
    API.isActiveTab = false;
};