function Application() {}

Application.prototype.init = function (data) {
    var me = this;

    setInterval(function() {me.updateTimes();}, 1000);
    $('body').on('mouseenter mouseleave', '[data-toggle="tooltip"]:not([data-ttfl])', function (event) {
        var dom = $(event.currentTarget);
        dom.attr("data-ttfl", "SCMS");
        dom.tooltip();
        dom.trigger(event.type);
    });

    if (data["API"] !== undefined) {
        API.init(data["API"]);
    }

    console.info("initialization successfully");
};

Application.prototype.updateTimes = function () {
    var elements = 0;
    $("[data-time]").each(function () {
        var time = new Time(parseInt($(this).attr("data-time"), 10));
        var dom = $(this);
        dom.html(time.format());
        if (time.isRelative()) {
            dom.attr("data-toggle", "tooltip");
            dom.attr("data-placement", "top");
            dom.attr("data-original-title", time.formatAbsolute());
        } else {
            dom.removeAttr("data-toggle");
            dom.removeAttr("data-placement");
            dom.removeAttr("data-original-title");
        }
        elements++;
    });
    //console.info("updated " + elements + " elements on " + (new Date()).toUTCString());
};