function User(id, name, prefix, colour, picture) {
    this.id = id;
    this.name = name;
    this.prefix = prefix;
    this.colour = colour;
    this.picture = picture;
}

User.prototype.id = 0;
User.prototype.name = "";
User.prototype.prefix = "";
User.prototype.colour = "#000000";
User.prototype.picture = "";

User.prototype.nameMe = function () {
    if (this.prefix === "") {
        return '<span class="name">' + this.name + '</span>';
    }
    return '<span class="name"><span style="color:' + this.colour + ';">[' + this.prefix + '] </span>' + this.name + '</span>';
};

User.prototype.getImage = function (classes) {
    if(classes === undefined) {
        return '<img src="' + this.picture + '" alt="Profilbild von ' + this.name + '" />';
    }
    return '<img src="' + this.picture + '" alt="Profilbild von ' + this.name + '" class="' + classes + '" />';
};